import edit_svg from '../../images/svg/edit.svg'
import emailSvg from '../../images/svg/email.svg'
import password_svg from '../../images/svg/password.svg'
import salesforce_svg from '../../images/svg/salesforce.svg'
import keycloak2_svg from '../../images/svg/keycloak2.svg'
import userSvg from '../../images/svg/user.svg'
import default_messages from '../core/ng-messages-default.html'

/**
 *
 * @param {ng.material.IIconProvider} $mdIconProvider
 * @param {ng.IHttpProvider} $httpProvider
 * @param {ng.ILocationProvider} $locationProvider
 * @param {ng.ICompileProvider} $compileProvider
 * @param {ng.material.IDialogProvider} $mdToastProvider
 * @param {import('@uirouter/angularjs').UrlMatcherFactory} $urlMatcherFactoryProvider
 */
export function configBlock(
  $mdIconProvider,
  $httpProvider,
  $locationProvider,
  $compileProvider,
  $mdToastProvider,
  $urlMatcherFactoryProvider
) {
  'ngInject'
  $locationProvider.html5Mode(true)
  $httpProvider.useApplyAsync(true)
  $urlMatcherFactoryProvider.strictMode(false)

  $compileProvider.debugInfoEnabled(false)
  $compileProvider.commentDirectivesEnabled(false)
  $compileProvider.cssClassDirectivesEnabled(false)

  $mdToastProvider.addPreset('success', _createToastPreset('green', 'Success'))
  $mdToastProvider.addPreset('error', _createToastPreset('red', 'Error'))

  $mdIconProvider
    .icon('user', userSvg)
    .icon('email', emailSvg)
    .icon('edit', edit_svg)
    .icon('password', password_svg)
    .icon('salesforce', salesforce_svg, 64)
    .icon('keycloak2', keycloak2_svg, 64)
}

/**
 *
 * @param {import('../blocks/gtag').AnalyticsService} analytics
 * @param {import('.').Config} config
 * @param {ng.ITemplateCacheService} $templateCache
 */
export function runBlock(analytics, config, $templateCache) {
  'ngInject'
  analytics.addTrackingId(config.google_analytics_tracking_id)
  analytics.sendPageview()
  $templateCache.put('ng-messages-default.html', default_messages)
}

/**
 *
 * @param {string} palette
 * @param {string} def
 * @returns
 */
function _createToastPreset(palette, def) {
  return {
    // use textContent to stay close to $mdToast.simple() preset
    argOption: 'textContent',
    methods: ['textContent'],
    options: () => ({
      position: 'top center',
      autoWrap: false,
      hideDelay: 5000,
      template: `
        <md-toast>
          <div class="md-toast-content" md-colors="{background:'default-${palette}'}">
            <span class="md-text-content">{{ toast.textContent || '${def}' }}</span>
            <md-button class="md-action" ng-click="toast.hide()">OK</md-button>
          </div>
        </md-toast>
      `,
      /**
       * @constructor
       * @param {ng.material.IToastService} $mdToast
       */
      controller($mdToast) {
        'ngInject'
        // @ts-ignore
        this.hide = $mdToast.hide
      },
      controllerAs: 'toast',
      bindToController: true
    })
  }
}
